import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import qs from 'qs'

import { ApiUrlPath, X_AUTH_TOKEN, IUser, AppMsg } from 'atw-shared/utils'
import { fetch, getAbortController, getLocalAuthUser } from 'client/services'

type TData = {
  data: {
    count: {
      questions: number
      answers: number
    }
    user: IUser
  }
}

type TError = {
  msg: AppMsg
}

export default () => {
  const { search } = useLocation()

  const { _id } = qs.parse(search.substring(1))

  const getUser = async () => {
    const token = getLocalAuthUser().token

    const abortController = getAbortController(25)

    const res = await fetch(
      ApiUrlPath.GetUser + '?' + new URLSearchParams({ _id: String(_id) }),
      {
        headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: token || '' },
        signal: abortController.signal,
      }
    )

    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useQuery<TData, TError>([ApiUrlPath.GetUser, _id], getUser, {
    enabled: false,
    cacheTime: 0,
  })
}
