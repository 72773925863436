import { useQuery } from '@tanstack/react-query'

import { ApiUrlPath, X_AUTH_TOKEN, AppMsg } from 'atw-shared/utils'
import { fetch, getAbortController, getLocalAuthUser } from 'client/services'

type TData = {
  data: string[]
}

type TError = {
  msg: AppMsg
}

type Args = {
  onSuccess?: (data: TData) => void
  onError?: (err: TError) => void
}

export default (args: Args) => {
  const getRandomQuestionsIds = async () => {
    const token = getLocalAuthUser().token

    const abortController = getAbortController(25)

    const res = await fetch(ApiUrlPath.GetRandomQuestions, {
      headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: token || '' },
      signal: abortController.signal,
    })

    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useQuery<TData, TError>([ApiUrlPath.GetRandomQuestions], getRandomQuestionsIds, {
    enabled: false,
    cacheTime: 0,
    onSuccess: args.onSuccess,
    onError: args.onError,
  })
}
