import { useMutation } from '@tanstack/react-query'
import { Error } from 'mongoose'

import { ApiUrlPath, X_AUTH_TOKEN, IUser, AppMsg } from 'atw-shared/utils'
import { fetch, getAbortController, getLocalAuthUser } from 'client/services'
import { UpdateUserDetailsFormValues } from 'client/utils'

type TData = {
  user: IUser
  msg: AppMsg
}

type TError = {
  msg: AppMsg
  valErr: { [field: string]: Error.ValidatorError }
}

type TVariables = UpdateUserDetailsFormValues

type Args = {
  onSuccess: (data: TData) => void
  onError: (err: TError) => void
}

export default ({ onSuccess, onError }: Args) => {
  const updateUser = async (formValues: TVariables): Promise<TData> => {
    const token = getLocalAuthUser().token

    const abortController = getAbortController(25)

    const res = await fetch(ApiUrlPath.UpdateUser, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: token || '' },
      body: JSON.stringify(formValues),
      signal: abortController.signal,
    })

    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useMutation<TData, TError, TVariables>(updateUser, {
    onSuccess,
    onError,
  })
}
