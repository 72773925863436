import { useQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'

import { ApiUrlPath, AppMsg } from 'atw-shared/utils'
import { TEN_MINUTES_IN_MS } from 'client/utils'
import { fetch, getAbortController } from 'client/services'

type TData = {
  count: {
    users: number
    questions: number
    answers: number
  }
}

type TError = {
  msg: AppMsg
}

export default () => {
  const { pathname } = useLocation()

  const getStats = async () => {
    const abortController = getAbortController(25)
    const res = await fetch(ApiUrlPath.GetStats, { signal: abortController.signal })
    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useQuery<TData, TError>([ApiUrlPath.GetStats], getStats, {
    enabled: pathname === '/home',
    refetchInterval: TEN_MINUTES_IN_MS,
    refetchIntervalInBackground: true,
  })
}
