import { useMutation } from '@tanstack/react-query'
import { Error } from 'mongoose'

import { ApiUrlPath, X_AUTH_TOKEN, IUser } from 'atw-shared/utils'
import { RegisterFormValues } from 'client/utils'
import { fetch, getAbortController, setLocalAuthUser } from 'client/services'

type TData = {
  user: IUser
}

type TError = {
  valErr: Record<string, Error.ValidatorError>
}

type TVariables = RegisterFormValues

type Args = {
  onSuccess: (data: TData) => void
}

export default ({ onSuccess }: Args) => {
  const registerUser = async (formValues: TVariables) => {
    const abortController = getAbortController(25)

    const res = await fetch(ApiUrlPath.RegisterUser, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formValues),
      signal: abortController.signal,
    })

    abortController.clear()

    if (res.ok) {
      const token = res.headers.get(X_AUTH_TOKEN)
      const body = (await res.json()) as TData
      setLocalAuthUser({ id: body.user._id.toString(), token })
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useMutation<TData, TError, TVariables>(registerUser, {
    onSuccess,
  })
}
