import { useQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'

import {
  ApiUrlPath,
  X_AUTH_TOKEN,
  IQuestion,
  Filter,
  AppMsg,
  IUser,
} from 'atw-shared/utils'

import { TEN_MINUTES_IN_MS } from 'client/utils'
import { fetch, getAbortController, getLocalAuthUser } from 'client/services'

export type TData = {
  count: number
  data: IQuestion[]
}

type TError = {
  msg: AppMsg
}

type Args = {
  showQuestionModal: boolean
  params: {
    filter: Filter
    user: IUser
    categories: string[]
    search: string
    pageNo: number
  }
  onSuccess?: (data: TData) => void
  onError?: (err: TError) => void
}

type ReqParams = {
  pageNo: string
  userId: string
  filter: Filter
  categories: string
  search: string
}

export default (args: Args) => {
  const { pathname } = useLocation()

  const getParams = (): ReqParams => {
    const { filter, user, categories, search, pageNo } = args.params
    const params = {} as ReqParams
    params.pageNo = String(pageNo)
    params.filter = filter
    if (categories.length > 0) {
      params.categories = categories.join('_')
    }
    if (search) {
      params.search = search
    }
    if (user) {
      params.userId = user._id.toString()
    }
    return params
  }

  const getQuestions = async () => {
    const token = getLocalAuthUser().token

    const abortController = getAbortController(25)

    const res = await fetch(
      ApiUrlPath.GetQuestions + '?' + new URLSearchParams(getParams()),
      {
        headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: token || '' },
        signal: abortController.signal,
      }
    )

    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useQuery<TData, TError>([ApiUrlPath.GetQuestions, args.params], getQuestions, {
    enabled: pathname === '/browse' && !args.showQuestionModal,
    keepPreviousData: true,
    cacheTime: 0,
    refetchInterval: TEN_MINUTES_IN_MS,
    refetchIntervalInBackground: true,
    onSuccess: args.onSuccess,
    onError: args.onError,
  })
}
