import { useMutation } from '@tanstack/react-query'
import values from 'lodash/values'

import { ApiUrlPath, X_AUTH_TOKEN, AppMsg, IAnswer } from 'atw-shared/utils'
import { fetch, getAbortController, getLocalAuthUser } from 'client/services'
import { QuestionSubmitFormValues } from 'client/utils'

type TData = {
  answer: IAnswer
}

type TError = {
  msg: AppMsg
}

type TVariables = {
  questionId: string
  formValues: QuestionSubmitFormValues
}

export default () => {
  const createAnswer = async ({ questionId, formValues }: TVariables) => {
    const token = getLocalAuthUser().token

    const selectedIndexes = []
    values(formValues.votes).forEach((selected, i) => selected && selectedIndexes.push(i))

    const abortController = getAbortController(25)

    const res = await fetch(
      ApiUrlPath.CreateAnswer + '?' + new URLSearchParams({ questionId }),
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: token || '' },
        body: JSON.stringify(selectedIndexes),
        signal: abortController.signal,
      }
    )

    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useMutation<TData, TError, TVariables>(createAnswer)
}
