import { useQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'

import { ApiUrlPath, X_AUTH_TOKEN, IQuestion, Filter, AppMsg } from 'atw-shared/utils'
import { TEN_MINUTES_IN_MS } from 'client/utils'
import { fetch, getAbortController, getLocalAuthUser } from 'client/services'

type TData = {
  count: number
  data: IQuestion[]
}

type TError = {
  msg: AppMsg
}

type Args = {
  showQuestionModal: boolean
  onError?: (err: TError) => void
}

export default (args: Args) => {
  const { pathname } = useLocation()

  const getTopQuestions = async () => {
    const token = getLocalAuthUser().token

    const abortController = getAbortController(25)

    const res = await fetch(
      ApiUrlPath.GetQuestions +
        '?' +
        new URLSearchParams({
          filter: Filter.Top,
        }),
      {
        headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: token || '' },
        signal: abortController.signal,
      }
    )

    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useQuery<TData, TError>([ApiUrlPath.GetQuestions, Filter.Top], getTopQuestions, {
    enabled: pathname === '/home' && !args.showQuestionModal,
    keepPreviousData: true,
    refetchInterval: TEN_MINUTES_IN_MS,
    refetchIntervalInBackground: true,
    onError: args.onError,
  })
}
