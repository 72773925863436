import { useMutation } from '@tanstack/react-query'
import { Error } from 'mongoose'

import { ApiUrlPath, X_AUTH_TOKEN, AppMsg } from 'atw-shared/utils'
import { fetch, getAbortController } from 'client/services'
import { RecoverPasswordFormValues } from 'client/utils'

type TData = {
  msg: AppMsg
}

type TError = {
  msg: AppMsg
  valErr: Record<string, Error.ValidatorError>
}

type TArgs = {
  formValues: RecoverPasswordFormValues
  token: string
}

export default () => {
  const recoverPassword = async (args: TArgs) => {
    const abortController = getAbortController(25)

    const res = await fetch(ApiUrlPath.RecoverPassword, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: args.token || '' },
      body: JSON.stringify({ newPassword: args.formValues.newPassword }),
      signal: abortController.signal,
    })

    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useMutation<TData, TError, TArgs>(recoverPassword)
}
