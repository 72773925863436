import { useMutation } from '@tanstack/react-query'

import { ApiUrlPath, X_AUTH_TOKEN, IReport, ReportReason, AppMsg } from 'atw-shared/utils'
import { fetch, getAbortController, getLocalAuthUser } from 'client/services'

type TData = {
  report: IReport
  msg: AppMsg
}

type TError = {
  msg: AppMsg
}

type TVariables = {
  questionId: string
  reasons: ReportReason[]
}

export default () => {
  const createReport = async ({ questionId, reasons }: TVariables) => {
    const token = getLocalAuthUser().token

    const abortController = getAbortController(25)

    const res = await fetch(
      ApiUrlPath.CreateReport +
        '?' +
        new URLSearchParams({
          questionId: String(questionId),
          reportReasons: reasons.join('-'),
        }),
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: token || '' },
        signal: abortController.signal,
      }
    )

    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useMutation<TData, TError, TVariables>(createReport)
}
