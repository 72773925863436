import { useQuery } from '@tanstack/react-query'

import { ApiUrlPath, AppMsg, IQuestionCategory } from 'atw-shared/utils'
import { fetch, getAbortController } from 'client/services'

type TBody = { categories: IQuestionCategory[] }

type TCategoryItem = {
  key: string
  value: string
  text: string
}

type TData = {
  en: TCategoryItem[]
  pl: TCategoryItem[]
}

type TError = {
  msg: AppMsg
}

type Args = {
  onError?: (err: TError) => void
}

const transformData = (body: TBody): TData => {
  return {
    en: body.categories
      .map(({ _id, name: { en } }) => ({
        key: _id,
        value: _id,
        text: en,
      }))
      .sort((a, b) => a.text.localeCompare(b.text)),
    pl: body.categories
      .map(({ _id, name: { pl } }) => ({
        key: _id,
        value: _id,
        text: pl,
      }))
      .sort((a, b) => a.text.localeCompare(b.text)),
  }
}

export default ({ onError }: Args) => {
  const getQuestionCategories = async () => {
    const abortController = getAbortController(25)
    const res = await fetch(ApiUrlPath.GetQuestionCategories, {
      signal: abortController.signal,
    })
    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TBody
      return Promise.resolve(transformData(body))
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useQuery<TData, TError>(
    [ApiUrlPath.GetQuestionCategories],
    getQuestionCategories,
    {
      staleTime: 1000 * 60 * 60 * 24 * 30,
      onError,
    }
  )
}
