import { useMutation } from '@tanstack/react-query'
import { Error } from 'mongoose'

import { ApiUrlPath, X_AUTH_TOKEN, IUser, AppMsg } from 'atw-shared/utils'
import { fetch, getAbortController, getLocalAuthUser } from 'client/services'
import { UpdatePasswordFormValues } from 'client/utils'

type TData = {
  data: {
    user: IUser
  }
  msg: AppMsg
}

type TError = {
  msg: AppMsg
  valErr: Record<string, Error.ValidatorError>
}

type TVariables = UpdatePasswordFormValues

export default () => {
  const updatePassword = async (formValues: TVariables) => {
    const token = getLocalAuthUser().token

    const abortController = getAbortController(25)

    const res = await fetch(ApiUrlPath.UpdatePassword, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: token || '' },
      body: JSON.stringify(formValues),
      signal: abortController.signal,
    })

    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useMutation<TData, TError, TVariables>(updatePassword)
}
