import { useQuery } from '@tanstack/react-query'

import { ApiUrlPath, AppMsg, X_AUTH_TOKEN } from 'atw-shared/utils'
import { fetch, getAbortController, getLocalAuthUser } from 'client/services'

type TData = {
  msg: AppMsg
}

type TError = {
  msg: AppMsg
}

type Args = {
  onSuccess: (data: TData) => void
  onError: (err: TError) => void
}

export default ({ onSuccess, onError }: Args) => {
  const getActivationLink = async () => {
    const token = getLocalAuthUser().token

    const abortController = getAbortController(25)

    const res = await fetch(ApiUrlPath.GetActivationLink, {
      headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: token || '' },
      signal: abortController.signal,
    })

    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useQuery<TData, TError>([ApiUrlPath.GetActivationLink], getActivationLink, {
    enabled: false,
    cacheTime: 0,
    onSuccess,
    onError,
  })
}
