import { useQuery } from '@tanstack/react-query'

import { ApiUrlPath, X_AUTH_TOKEN, IQuestion, AppMsg } from 'atw-shared/utils'
import { fetch, getAbortController, getLocalAuthUser } from 'client/services'

export type TData = {
  count: number
  data: IQuestion[]
}

type TError = {
  msg: AppMsg
}

type Args = {
  questionId: string
}

export default (args: Args) => {
  const getQuestion = async () => {
    const token = getLocalAuthUser().token

    const abortController = getAbortController(25)

    const res = await fetch(
      ApiUrlPath.GetQuestion +
        '?' +
        new URLSearchParams({
          _id: String(args.questionId),
        }),
      {
        headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: token || '' },
        signal: abortController.signal,
      },
      0
    )

    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useQuery<TData, TError>([ApiUrlPath.GetQuestion, args.questionId], getQuestion, {
    enabled: false,
    cacheTime: 0,
    keepPreviousData: true,
  })
}
