import { useMutation } from '@tanstack/react-query'

import { ApiUrlPath, X_AUTH_TOKEN, IUser, AppMsg } from 'atw-shared/utils'
import { AuthType, AuthenticationFormValues } from 'client/utils'

import {
  fetch,
  getAbortController,
  setLocalAuthUser,
  removeLocalAuthUser,
} from 'client/services'

type TData = {
  user: IUser
}

type TError = {
  msg: AppMsg
}

type TVariables = {
  credentials?: AuthenticationFormValues
  token?: string
}

type Args = {
  authType: AuthType
  onSuccess: (data: TData) => void
  onError: (err: TError) => void
}

export default ({ authType, onSuccess, onError }: Args) => {
  const authenticateUser = async ({ credentials, token = '' }: TVariables) => {
    const abortController = getAbortController(25)

    const res = await fetch(ApiUrlPath.AuthenticateUser, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: token },
      body: credentials ? JSON.stringify(credentials) : null,
      signal: abortController.signal,
    })

    abortController.clear()

    if (res.ok) {
      const token = res.headers.get(X_AUTH_TOKEN)
      const body = (await res.json()) as TData
      setLocalAuthUser({ id: body.user._id.toString(), token })
      return Promise.resolve(body)
    } else {
      removeLocalAuthUser()
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useMutation<TData, TError, TVariables>(
    [ApiUrlPath.AuthenticateUser, authType],
    authenticateUser,
    {
      onSuccess,
      onError,
    }
  )
}
