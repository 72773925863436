import { useQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'

import { ApiUrlPath, X_AUTH_TOKEN, IUser, Filter, AppMsg } from 'atw-shared/utils'
import { TEN_MINUTES_IN_MS } from 'client/utils'
import { fetch, getAbortController, getLocalAuthUser } from 'client/services'

type TData = {
  count: number
  data: IUser[]
}

type TError = {
  msg: AppMsg
}

export default () => {
  const { pathname } = useLocation()

  const getTopUsers = async () => {
    const token = getLocalAuthUser().token

    const abortController = getAbortController(25)

    const res = await fetch(ApiUrlPath.GetTopUsers, {
      headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: token || '' },
      signal: abortController.signal,
    })

    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useQuery<TData, TError>([ApiUrlPath.GetTopUsers, Filter.Top], getTopUsers, {
    enabled: pathname === '/home',
    keepPreviousData: true,
    refetchInterval: TEN_MINUTES_IN_MS,
    refetchIntervalInBackground: true,
    staleTime: TEN_MINUTES_IN_MS,
  })
}
