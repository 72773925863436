import { useMutation } from '@tanstack/react-query'
import { Error } from 'mongoose'

import { ApiUrlPath, X_AUTH_TOKEN, AppMsg } from 'atw-shared/utils'
import { fetch, getAbortController, getLocalAuthUser } from 'client/services'
import { RecoverPasswordFormValues } from 'client/utils'

type TData = {
  msg: AppMsg
}

type TError = {
  msg: AppMsg
  valErr: Record<string, Error.ValidatorError>
}

export default () => {
  const getRecoveryLink = async ({ email }: RecoverPasswordFormValues) => {
    const token = getLocalAuthUser().token

    const abortController = getAbortController(25)

    const res = await fetch(
      ApiUrlPath.GetRecoveryLink + '?' + new URLSearchParams({ email }),
      {
        headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: token || '' },
        signal: abortController.signal,
      }
    )

    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useMutation<TData, TError, RecoverPasswordFormValues>(getRecoveryLink)
}
