import { useQuery } from '@tanstack/react-query'

import { ApiUrlPath, AppMsg, ICountry } from 'atw-shared/utils'
import { fetch, getAbortController } from 'client/services'

type TBody = { countries: ICountry[] }

type TCountryItem = {
  key: string
  value: string
  flag: string
  text: string
}

type TData = {
  en: TCountryItem[]
  pl: TCountryItem[]
}

type TError = {
  msg: AppMsg
}

type Args = {
  onError?: (err: TError) => void
}

const transformData = (body: TBody): TData => {
  return {
    en: body.countries
      .map(({ _id, name: { en } }) => ({
        key: _id,
        value: _id,
        flag: _id.toLowerCase(),
        text: en,
      }))
      .sort((a, b) => a.text.localeCompare(b.text)),
    pl: body.countries
      .map(({ _id, name: { pl } }) => ({
        key: _id,
        value: _id,
        flag: _id.toLowerCase(),
        text: pl,
      }))
      .sort((a, b) => a.text.localeCompare(b.text)),
  }
}

export default ({ onError }: Args) => {
  const getCountries = async () => {
    const abortController = getAbortController(25)
    const res = await fetch(ApiUrlPath.GetCountries, { signal: abortController.signal })
    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TBody
      return Promise.resolve(transformData(body))
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useQuery<TData, TError>([ApiUrlPath.GetCountries], getCountries, {
    staleTime: 1000 * 60 * 60 * 24 * 30,
    onError,
  })
}
