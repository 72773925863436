import { useMutation } from '@tanstack/react-query'

import { ApiUrlPath, AppMsg, X_AUTH_TOKEN } from 'atw-shared/utils'
import { fetch, getAbortController, getLocalAuthUser } from 'client/services'

type TData = {
  msg: AppMsg
}

type TError = {
  msg: AppMsg
}

type TVariables = {
  _id: string
  data: string
}

export default () => {
  const updateUserAvatar = async ({ _id, data }: TVariables) => {
    const token = getLocalAuthUser().token

    const abortController = getAbortController(25)

    const res = await fetch(ApiUrlPath.UpdateAvatar, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: token || '' },
      body: JSON.stringify({ _id, data }),
      signal: abortController.signal,
    })

    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useMutation<TData, TError, TVariables>(updateUserAvatar)
}
