import { useMutation } from '@tanstack/react-query'

import { ApiUrlPath, X_AUTH_TOKEN, IQuestion, AppMsg } from 'atw-shared/utils'
import { fetch, getAbortController, getLocalAuthUser } from 'client/services'

type TData = {
  question: IQuestion
  msg: AppMsg
}

type TError = {
  msg: AppMsg
}

export default () => {
  const createQuestion = async (payload: IQuestion) => {
    const token = getLocalAuthUser().token

    const abortController = getAbortController(25)

    const res = await fetch(ApiUrlPath.CreateQuestion, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [X_AUTH_TOKEN]: token || '' },
      body: JSON.stringify(payload),
      signal: abortController.signal,
    })

    abortController.clear()

    if (res.ok) {
      const body = (await res.json()) as TData
      return Promise.resolve(body)
    } else {
      const err = (await res.json()) as TError
      return Promise.reject(err)
    }
  }

  return useMutation<TData, TError, IQuestion>(createQuestion)
}
